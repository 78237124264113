import ApiService from "@/common/api.service";

// action types
export const GET_REPORT_LIST = "/report";

// mutation types
export const SET_REPORT_LIST = "setReportList";

const state = {
    reports: [],
};

const getters = {
    listReport(state) {
        return state.reports;
    },
};

const actions = {
    [GET_REPORT_LIST](context,payload) {
        return new Promise((resolve) => {
            ApiService.query(GET_REPORT_LIST,payload)
                .then(( data ) => {
                    context.commit(SET_REPORT_LIST, data.data);
                    resolve(data);
                })
        });
    },
};

const mutations = {
    [SET_REPORT_LIST](state, data) {
        state.reports = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
