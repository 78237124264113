import ApiService from "@/common/api.service";

// action types
export const SAVE_CONTACT = "/contact/save";
export const GET_CONTACT = "/contact/get";

export const SET_CONTACT = "setContact";

const state = {
    contact: {},
};

const getters = {
    contact(state) {
        return state.contact;
    },
};

const actions = {
    [GET_CONTACT](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_CONTACT,payload)
                .then(({data}) => {
                    context.commit(SET_CONTACT, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [SAVE_CONTACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_CONTACT, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_CONTACT](state, error) {
        state.contact = error;
    },


};

export default {
    state,
    actions,
    mutations,
    getters
};
