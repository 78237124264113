import ApiService from "@/common/api.service";

// action types
export const BUILD_SERVER = "/setting/build-server";
export const SAVE_ADS = "/setting/add-ads";
export const GET_SCRIPT_ADS = "/setting/get-ads";

// mutation types

const state = {
  errors: null,
  blogs: [],
  ads: []
};

const getters = {
    ads(state) {
        return state.ads;
    },
};

const actions = {
  [BUILD_SERVER](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(BUILD_SERVER,payload)
          .then(({data}) => {
            resolve(data);
          })
          .catch(({response}) => {
            reject(response)
          });
    });
  },
  [SAVE_ADS](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(SAVE_ADS,payload)
          .then(({data}) => {
            resolve(data);
          })
          .catch(({response}) => {
            reject(response)
          });
    });
  },
    [GET_SCRIPT_ADS](context) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_SCRIPT_ADS)
                .then(({data}) => {
                    context.commit("setAds", data);
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
};

const mutations = {
    setAds(state, data) {
        state.ads = data;
    },
};

export default {
  state,
  actions,
  mutations,
  getters
};
