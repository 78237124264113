import ApiService from "@/common/api.service";

// action types
export const GET_LIST_USERS = "/user/list";
export const GET_LIST_USERS_ASK = "/user/list-user-ask";
export const DETAIL_USER = "/user/detail";
export const CREATE_USER = "/user/create";
export const UPDATE_USER = "/user/update";
export const CHANGE_PASSWORD = "/user/change-password";
export const DELETE_USER = "/user/delete";
export const DELETE_USER_ASK = "/user/delete-user-ask";

// mutation types
export const SET_USERS = "setUsers";
export const SET_USERS_ASK = "setUsersAsk";
export const SET_USER = "setUser";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    listUsers: [],
    users: [],
    user: {},
};

const getters = {
    users(state) {
        return state.users;
    },
    listUsers(state) {
        return state.listUsers;
    },
    user(state) {
        return state.user;
    },
};

const actions = {
    [GET_LIST_USERS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST_USERS, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_USERS, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [GET_LIST_USERS_ASK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST_USERS_ASK, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_USERS_ASK, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [DETAIL_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(DETAIL_USER, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_USER, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [CREATE_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(CREATE_USER, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_USER, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [UPDATE_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_USER, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_USER, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [CHANGE_PASSWORD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(CHANGE_PASSWORD, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_USER, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [DELETE_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_USER, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [DELETE_USER_ASK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_USER_ASK, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_USERS](state, data) {
        state.users = data.data;
        state.errors = {};
    },
    [SET_USERS_ASK](state, data) {
        state.listUsers = data.data;
        state.errors = {};
    },
    [SET_USER](state, data) {
        state.user = data.data;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
