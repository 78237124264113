import ApiService from "@/common/api.service";

// action types
export const SAVE_IMAGE = "/image/upload";
export const SAVE_IMAGE_TINYMCE = "/ckeditor/image/upload";


// mutation types

const state = {
      images: [],
};

const getters = {
     
};

const actions = {
    [SAVE_IMAGE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_IMAGE, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [SAVE_IMAGE_TINYMCE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_IMAGE_TINYMCE, payload)
                .then((data) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    }

};

const mutations = {
};

export default {
      state,
      actions,
      mutations,
      getters
};
