import ApiService from "@/common/api.service";

// action types
export const LIST_ASKS = "/ask/list";
export const DETAIL_ASK = "/ask/detail";
export const SAVE_ASK = "/ask/save";
export const UPDATE_ASK = "/ask/update";
export const COUNT_ASK = "/ask/count";
export const DELETE_ASK = "/ask/delete";


const state = {
    ask:{
        id: '',
        slug: "",
    },
    asks:[],
    total:0,
};

const getters = {
    listAsk(state) {
        return state.asks;
    },
    detailAsk(state) {
        return state.ask;
    },
    totalAsk(state) {
        return state.total;
    },
};

const actions = {
    [LIST_ASKS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(LIST_ASKS, payload)
                .then(data => {
                    if (data.status) {
                        context.commit("setAsk", data);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [COUNT_ASK](context, payload) {
        return new Promise(resolve => {
            ApiService.query(COUNT_ASK, payload).then(data => {
                if (data.status) {
                    context.commit("setTotal", data);
                }
                resolve(data);
            });
        });
    },

    [DETAIL_ASK](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(DETAIL_ASK, payload)
                .then(data => {
                    if (data.status) {
                        context.commit("setAskDetail", data);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [SAVE_ASK](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_ASK, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [UPDATE_ASK](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_ASK, payload)
                .then( data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [DELETE_ASK](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_ASK, id)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    setAsk(state, data) {
        state.asks = data.data;
    },
    setAskDetail(state, data) {
        state.ask = data.data;
    },
    setTotal(state, data) {
        state.total = data.data;
    },

};

export default {
    state,
    actions,
    mutations,
    getters
};
