import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/views/theme/Base"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/views/pages/Dashboard.vue")
                },
                {
                    path: "/content-contact",
                    name: "content-contact",
                    component: () => import("@/views/pages/content-contact.vue")
                }, {
                    path: "/privacy-policy",
                    name: "privacy-policy",
                    component: () => import("@/views/pages/privacy-policy.vue")
                },
                {
                    path: "/rule",
                    name: "rule",
                    component: () => import("@/views/pages/rule.vue")
                },
                {
                    path: "/list-reward",
                    name: "list-reward",
                    component: () => import("@/views/pages/list-reward.vue")
                },
                {
                    path: "/add-point",
                    name: "add-point",
                    component: () => import("@/views/pages/add-point.vue")
                },
                {
                    path: "/title",
                    name: "title",
                    component: () => import("@/views/pages/title.vue")
                },
                {
                    path: "/build-serve",
                    name: "build-serve",
                    component: () => import("@/views/pages/build-serve.vue")
                },
                {
                    path: "/delete-cache",
                    name: "delete-cache",
                    component: () => import("@/views/pages/delete-cache.vue")
                },
                {
                    path: "/add-ads",
                    name: "add-ads",
                    component: () => import("@/views/pages/add-ads.vue")
                },
                {
                    path: "/list-user-ask",
                    name: "list-user-ask",
                    component: () => import("@/views/pages/list-user.vue")
                },
                {
                    path: "/user",
                    name: "user",
                    component: () => import("@/views/pages/user/User.vue")
                },
                {
                    path: "/user/edit/:id",
                    name: "user-detail",
                    component: () => import("@/views/pages/user/Detail.vue")
                },
                {
                    path: "/user/add",
                    name: "user-add",
                    component: () => import("@/views/pages/user/Detail.vue")
                },
                {
                    path: "/Setting",
                    name: "setting",
                    component: () => import("@/views/pages/Setting.vue")
                },
                {
                    path: "/subject",
                    name: "subject",
                    component: () => import("@/views/pages/subject/SubjectList.vue")
                },
                {
                    path: "/subject",
                    name: "subject-detail",
                    component: () => import("@/views/pages/subject/SubjectDetail.vue")
                },
                {
                    path: "/subject/add",
                    name: "subject-add",
                    component: () => import("@/views/pages/subject/SubjectDetail.vue")
                },
                {
                    path: "/grade/list",
                    name: "grade",
                    component: () => import("@/views/pages/class/GradeList.vue")
                },
                {
                    path: "/grade",
                    name: "grade-detail",
                    component: () => import("@/views/pages/class/GradeDetail.vue")
                },
                {
                    path: "/lesson",
                    name: "lesson",
                    component: () => import("@/views/pages/lesson/Lesson.vue")
                },


                {
                    path: "/ask",
                    name: "ask",
                    component: () => import("@/views/pages/ask/List.vue")
                },
                {
                    path: "/ask/detail",
                    name: "ask-detail",
                    component: () => import("@/views/pages/ask/Detail.vue")
                },
                {
                    path: "/ask/edit/:slug",
                    name: "ask-edit",
                    component: () => import("@/views/pages/ask/Detail.vue")
                },

                {
                    path: "/answer",
                    name: "answer",
                    component: () => import("@/views/pages/answer/List.vue")
                },
                {
                    path: "/answer/detail",
                    name: "answer-detail",
                    component: () => import("@/views/pages/answer/Detail.vue")
                },
                {
                    path: "/answer/edit/:id",
                    name: "answer-edit",
                    component: () => import("@/views/pages/answer/Detail.vue")
                },
                {
                    path: "/notification",
                    name: "notification",
                    component: () => import("@/views/pages/notification/List.vue")
                },
                {
                    path: "/report",
                    name: "report",
                    component: () => import("@/views/pages/report/List.vue")
                },

                {
                    path: "/lesson/edit/:slug",
                    name: "lession-detail",
                    component: () => import("@/views/pages/lesson/Detail.vue")
                },
                {
                    path: "/lesson/add",
                    name: "lession-add",
                    component: () => import("@/views/pages/lesson/Detail.vue")
                },

                {
                    path: "/news-category",
                    name: "news-category",
                    component: () => import("@/views/pages/category/NewsCategory.vue")
                },
                {
                    path: "/news-category/edit/:slug",
                    name: "category-detail",
                    component: () => import("@/views/pages/category/CategoryDetail.vue")
                },
                {
                    path: "/news-category/add",
                    name: "category-add",
                    component: () => import("@/views/pages/category/CategoryDetail.vue")
                },
                {
                    path: "/config",
                    name: "config",
                    component: () => import("@/views/pages/Config.vue")
                },
                {
                    path: "/list-book",
                    name: "list-book",
                    component: () => import("@/views/pages/book/list.vue")
                },
                {
                    path: "/book",
                    name: "detail-book",
                    component: () => import("@/views/pages/book/detail.vue")
                },
                {
                    path: "/add-book",
                    name: "add-book",
                    component: () => import("@/views/pages/book/detail.vue")
                },
                {
                    path: "/term-of-use",
                    name: "term-of-use",
                    component: () => import("@/views/pages/term-of-use.vue")
                }
            ]
        },

        {
            path: "/error",
            name: "error",
            component: () => import("@/views/pages/error/Error.vue"),
            children: [
                {
                    path: "error-1",
                    name: "error-1",
                    component: () => import("@/views/pages/error/Error-1.vue")
                },
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/views/pages/error/Error-2.vue")
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/views/pages/error/Error-3.vue")
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/views/pages/error/Error-4.vue")
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/views/pages/error/Error-5.vue")
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/views/pages/error/Error-6.vue")
                }
            ]
        },
        {
            path: "/",
            component: () => import("@/views/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/views/pages/auth/Login")
                }
                // {
                //   name: "register",
                //   path: "/register",
                //   component: () => import("@/views/pages/auth/Register")
                // }
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/views/pages/error/Error-1.vue")
        }
    ]
});
