import ApiService from "@/common/api.service";

// action types
export const LIST_ANSWERS = "/answer/list";
export const DETAIL_ANSWER = "/answer/detail";
export const SAVE_ANSWER = "/answer/save";
export const UPDATE_ANSWER = "/answer/update";
export const COUNT_ANSWER = "/answer/count";
export const DELETE_ANSWER = "/answer/delete";


const state = {
    answer:{},
    answers:[],
    total:0,
};

const getters = {
    listAnswer(state) {
        return state.answers;
    },
    detailAnswer(state) {
        return state.answer;
    },
    totalAnswer(state) {
        return state.total;
    },
};

const actions = {
    [LIST_ANSWERS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(LIST_ANSWERS, payload)
                .then(data => {
                    if (data.status) {
                        context.commit("setAnswer", data);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [COUNT_ANSWER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(COUNT_ANSWER, payload).then(data => {
                if (data.status) {
                    context.commit("setTotal", data);
                }
                resolve(data);
            });
        });
    },

    [DETAIL_ANSWER](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(DETAIL_ANSWER, payload)
                .then(data => {
                    if (data.status) {
                        context.commit("setAnswerDetail", data);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [SAVE_ANSWER](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_ANSWER, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [UPDATE_ANSWER](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_ANSWER, payload)
                .then( data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [DELETE_ANSWER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_ANSWER, id)
                .then(data => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    setAnswer(state, data) {
        state.answers = data.data;
    },
    setAnswerDetail(state, data) {
        state.answer = data.data;
    },
    setTotal(state, data) {
        state.total = data.data;
    },

};

export default {
    state,
    actions,
    mutations,
    getters
};
