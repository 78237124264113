import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
// import { API_URL } from "@/common/config";
import router from '@/router'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return new Promise((resolve) => {
      Vue.axios.get(`${resource}`,{params}).then(({ data }) => {
        if (data.code == 401) {
          router.push({name:'login'});
        }
        resolve(data);
      })
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource,params) {
    return new Promise((resolve) => {
      Vue.axios.get(`${resource}/${params}`).then(({ data }) => {
        if (data.code == 401) {
          router.push({name:'login'});
        }
        resolve(data);
      })
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios.post(`${resource}`, params).then(({ data }) => {
        resolve(data);
      }).catch(error => {
        if (error.response.status == 400) {
          router.push({name:'login'});
        }
        reject(error);
      });
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return new Promise((resolve, reject) => {
      Vue.axios.put(`${resource}/${slug}`, params).then(({ data }) => {
        resolve(data);
      }).catch(error => {
        if (error.response.status == 400) {
          router.push({name:'login'});
        }
        reject(error);
      });
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios.put(`${resource}`, params).then(({ data }) => {
        resolve(data);
      }).catch(error => {
        if (error.response.status == 400) {
          router.push({name:'login'});
        }
        reject(error);
      });
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource,params) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete(`${resource}/${params}`).then(({ data }) => {
        resolve(data);
      }).catch(error => {
        if (error.response.status == 400) {
          router.push({name:'login'});
        }
        reject(error);
      });
    });
  }
};

export default ApiService;
