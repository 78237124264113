import ApiService from "@/common/api.service";

// action type
export const GET_LIST_BOOK = "/book/list";
export const GET_DETAIL_BOOK = "/book/detail";
export const SAVE_BOOK = "/book/create";
export const UPDATE_BOOK = "/book/update";
export const DELETE_BOOK = "/book/delete";
export const SET_LIST_BOOK = "setListBook";
export const SET_DETAIL_BOOK = "setDetailBook";

export const SET_ERROR = "setError";

const state = {
    errors: null,
    books: [],
    book: {},
};

const getters = {
    listBooks(state) {
        return state.books;
    },
    detailBook(state) {
        return state.book;
    }
};

const actions = {
    [GET_LIST_BOOK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST_BOOK, payload)
                .then(( data ) => {
                    context.commit(SET_LIST_BOOK, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                });
        });
    },

    [GET_DETAIL_BOOK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_DETAIL_BOOK, payload)
                .then(( data ) => {
                    context.commit(SET_DETAIL_BOOK, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                });
        });
    },

    [SAVE_BOOK](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_BOOK, payload)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },

    [UPDATE_BOOK](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_BOOK}/${payload.id}`, payload)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },

    [DELETE_BOOK](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${DELETE_BOOK}/${payload}`)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LIST_BOOK](state, data) {
        state.books = data;
        state.errors = {};
    } ,
    [SET_DETAIL_BOOK](state, data) {
        state.book = data;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
